<template>
  <div v-loading="loading">
    <div style="margin: 20px">
      <el-card>
        <div class="f-s-14-w-s-p-l">
          <b>Показатель:</b>
          <p class="m-t-5">
            {{ data.model.pki.name }}
          </p>
          <b>Дата изменения:</b>
          <p class="m-t-5">
            {{ getFormatDate(data.model.update_at) }}
          </p>
          <b>Причина изменений:</b>
          <p class="m-t-5">
            {{ data.model.description }}
          </p>
          <div v-if="data.model.file">
            <b>Файл:</b>
            <p class="m-t-5">
              <el-link type="primary" @click="downloadFile( data.model.file)">
                {{ data.model.file.name }}
              </el-link>
            </p>
          </div>
          <b>Кто изменил:</b>
          <p class="m-t-5">
            {{ data.model.torisUser.fio }}
          </p>
        </div>
      </el-card>
    </div>
    <div style="margin: 20px">
      <el-card>
        <label>История изменений</label>
        <el-divider/>
        <el-table
          ref="table"
          :data="data.data"
          border
          fit
          highlight-current-row
          size="mini"
          class="main-table">
          <el-table-column
            v-for="column in columns"
            sortable
            :key="column.field"
            :label="column.label"
            :align="column.align || 'center'"
            :prop="column.field"
            :width="column.width">
            <template slot-scope="scope">
              <template v-if="typeof scope.row[column.field] === 'object'">
                <Chart v-if="!loading" :options="getChartData(scope.row, column)" class="chart" height="700px"/>
              </template>
              <template v-else>
                {{ getValue(scope.row, column) }}
              </template>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
  </div>
</template>

<script>

import { getPKIHistory } from '@/api/api'
import Chart from '@/components/Chart'
import { getFormatDate } from '@/utils'
import { downloadFile } from '@/api/file'

export default {
  components: {
    Chart
  },
  data () {
    return {
      loading: false,
      data: {
        data: [],
        model: {
          update_at: null,
          pki: {
            name: ''
          },
          description: null,
          file: {
            name: ''
          },
          torisUser: {
            fio: ''
          }
        }
      },
      columns: [
        {
          field: 'field',
          label: 'Поле',
          width: '200px'
        },
        {
          field: 'old_value',
          label: 'Было'
        },
        {
          field: 'new_value',
          label: 'Стало'
        }
      ],
      chartData: {
        series: {
          type: 'sankey',
          layout: 'none',
          emphasis: {
            focus: 'adjacency'
          },
          data: [],
          links: []
        }
      }
    }
  },
  created () {
    this.loading = true
    getPKIHistory(this.$route.params.id).then(response => {
      this.data = response.data
    }).finally(() => {
      this.loading = false
    })
  },
  methods: {
    getFormatDate: getFormatDate,
    getValue (row, column) {
      return row[column.field]
    },
    getChartData (row, column) {
      return { series: Object.assign({}, this.chartData.series, row[column.field]) }
    },
    downloadFile (file) {
      downloadFile(file.id).then(({ data }) => {
        const url = URL.createObjectURL(new Blob([data], { type: data.type }))
        if (data.type === 'application/pdf') {
          window.open(url, '_blank')
        } else {
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.style = 'display: none'
          a.href = url
          a.download = file.name
          a.target = '_blank'
          a.click()
        }
      })
    }
  }
}
</script>

<style scoped>
.m-t-5 {
  margin-top: 5px;
  line-height: 1.5;
}

.f-s-14-w-s-p-l {
  font-size: 14px;
  white-space: pre-line;
}
</style>
